<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Edit user</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-select
          :items="userGroups"
          item-text="name"
          item-value="id"
          v-model="user.user_group_id">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Name"
          v-model="user.name"></v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          label="Initials"
          v-model="user.initials"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-text-field
          label="Telephone"
          v-model="user.direct_tel"></v-text-field>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          label="Email"
          v-model="user.email"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-text-field
          label="Mobile"
          v-model="user.mobile"></v-text-field>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          label="Title"
          v-model="user.title"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-text-field
          type="password"
          label="Password"
          v-model="user.password"></v-text-field>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          type="password"
          label="Repeat password"
          v-model="user.repeatPassword"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-btn @click="saveUser">Save User</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="400"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            x-small
            @click="message = '', showMessage = false"
          >
            Close
          </v-btn>
          &nbsp;
          <router-link to="/admin/users">
            <v-btn
              x-small
              color="orange darken-2">
              Back to all users
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>  
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminUserEdit',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  data() {
    return {
      user: {},
      message: '',
      showMessage: false,
      userGroups: [],
    };
  },
  methods: {
    addAnother() {
      this.showMessage = false;
      this.user = {};
    },
    saveUser() {
      if (this.user.password !== this.user.repeatPassword) {
        this.message = 'The passwords do not match';
        this.showMessage = true;
      } else {
        axios.post(`/users/update.json?token=${this.token}&user=${this.userid}`, this.user)
          .then((response) => {
            this.message = response.data.message;
            this.showMessage = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getUser() {
			const userId = this.$route.params.id;
			axios.get(`/users/getSingle/${userId}.json?token=${this.token}`)
				.then((response) => {
					this.user = response.data.user;
				})
				.catch((error) => {
					console.log(error);
				});
		},
    getUserGroups() {
      axios.get(`/userGroups/getAll.json?token=${this.token}`)
				.then((response) => {
					this.userGroups = response.data.usergroups;
				})
				.catch((error) => {
					console.log(error);
				});
    },
  },
  mounted() {
    this.getUser();
    this.getUserGroups();
  },
};
</script>
